import * as React from 'react'
import styled from 'styled-components'
import { SocialLinks, SimplePracticeButton, Button, Figcaption, BookingButton } from '../../components/TComponents'
import { useI18n } from '@bestyled/primitives'
import Image from '../../components/Image'
import mediaqueries from '../../styles/media'

interface SpecialistHeroProps {
  specialist: any
  location: Location
}

const SpecialistHero: React.FC<SpecialistHeroProps> = ({ specialist, location }) => {
  const { t } = useI18n()

  return (
    <Hero>
      <HeroImage>
        <RoundedImage src={{ ...specialist.avatar.medium, aspectRatio: 1 }} />
      </HeroImage>
      <Heading>{specialist.name}</Heading>
      <Subheading dangerouslySetInnerHTML={{ __html: specialist.suffix }} />
      <Subheading dangerouslySetInnerHTML={{ __html: specialist.title }} />
      <MetaData>
        {t('Slipway.Specialties')}: {specialist.specialties}
      </MetaData>
      {specialist.couples === 'Yes' ? (
        <MetaData>
          {t('Slipway.Couples')}:{t('Slipway.Yes')}
        </MetaData>
      ) : (
        <MetaData>
          {t('Slipway.Couples')}:{t('Slipway.No')}
        </MetaData>
      )}
      <MetaData>
        {t('Slipway.Ages')}: {specialist.ages}
      </MetaData>

      {specialist.newpatients === false || specialist.newpatients === null ? (
        // not accepting new patients
        <MetaDataNewPatients>{t('Slipway.Not Accepting new')}</MetaDataNewPatients>
      ) : (
        <MetaData>
          {specialist.section === 'specialist' && (
            <>
              {specialist.bookings ? (
                <BookingButton width={300} mt={30} to={`${location.href}/booking`}>
                  {t('Slipway.Request appointment')}
                </BookingButton>
              ) : (
                <>
                  <SimplePracticeButton name={specialist.name} state={specialist.states.split(',')[0]}>
                    {t('Slipway.Request appointment')}
                  </SimplePracticeButton>

                  <Figcaption>
                    Just select <b>{specialist.name}</b> on the <br />
                    next screen when using the button above
                  </Figcaption>
                </>
              )}
            </>
          )}
        </MetaData>
      )}

      <BioBody
        dangerouslySetInnerHTML={{
          __html: specialist.bio || ''
        }}
      />
      <Social>
        <SocialLinks links={specialist.social} />
      </Social>
    </Hero>
  )
}

export default SpecialistHero

const BioBody = styled.article`
  position: relative;
  padding: 40px 0 35px;
  padding-left: 68px;
  transition: background 0.2s linear;

  & h5 {
    padding-left: 2.5em;
    text-indent: -2.5em;
  }

  & p {
    font-family: Source Serif Pro, serif;
    position: relative;
    padding: 0.5em 2em 0.5em 2em;
    margin: 1em 3em 1em 2em;
    font-size: 1.2em;
  }

  ${mediaqueries.desktop`
    padding-left: 53px;

  `}

  ${mediaqueries.tablet`
    padding: 70px 0 80px;
  `}

  ${mediaqueries.phablet`
    padding: 15px 0;

    & p {
      font-family: Source Serif Pro, serif;
      position: relative;
      padding: 0.5em 1em 0.5em 1em;
      margin: 1em 1em 1em 1em;
      font-size: 1.2em;
    }
  `}
`

const Hero = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 35px auto 110px;
`

const HeroImage = styled.div`
  position: relative;
  z-index: 1;
  height: 164px;
  width: 164px;
  margin-bottom: 35px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid ${(p) => p.theme.colors.background};
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);

  ${mediaqueries.tablet`
    width: 146px;
    height: 146px;
  `}

  ${mediaqueries.phablet`
    width: 136px;
    height: 136px;
    margin-bottom: 25px;
  `}
`

const RoundedImage = styled(Image)`
  border-radius: 50%;
`

const Heading = styled.h1`
  font-size: 38px;
  font-family: ${(p) => p.theme.fonts.hero};
  color: ${(p) => p.theme.colors.primary};
  margin-bottom: 15px;
  font-weight: 600;

  ${mediaqueries.tablet`
  `}

  ${mediaqueries.phablet`
  `}
`

const Subheading = styled.p`
  margin: 0 auto;
  max-width: 450px;
  color: ${(p) => p.theme.colors.grey};
  font-size: 18px;
  font-family: ${(p) => p.theme.fonts.hero};
  line-height: 1.4;
  text-align: center;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`

const Social = styled.div`
  display: flex;
  align-items: center;
  margin-top: 35px;

  ${mediaqueries.phablet`
    font-size: 14px;
  `}
`
const MetaData = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.colors.grey};
  opacity: 0.75;

  ${mediaqueries.phablet`
    max-width: 100%;
  `}
`
const MetaDataNewPatients = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: ${(p) => p.theme.colors.accent};
  opacity: 0.75;

  ${mediaqueries.phablet`
    max-width: 100%;
  `}
`
